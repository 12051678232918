@import '~@ui/styles/tools';
@import 'src/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin feature-heading {
    color: #1c2723;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;

    @include media-tablet {
        font-size: 20px;
    }
}

@mixin feature-description {
    font-size: 20px;
    line-height: normal;
    color: $color-text-light;
    margin-bottom: 0;

    @include media-tablet {
        font-size: 16px;
    }
}

.block-wrapper {
    &:first-of-type {
        .image-wrapper {
            height: 100%;
            width: 10%;
            img {
                width: auto !important;
                height: 182px !important;
                aspect-ratio: 1.09;
                bottom: -32px !important;
                left: unset !important;
                top: unset !important;
            }
        }
    }

    &:nth-of-type(2) {
        .image-wrapper {
            height: 100%;
            width: 10%;

            img {
                height: 250px !important;
                width: 250px !important;
                bottom: -32px !important;
                right: -32px !important;
                top: unset !important;
                left: unset !important;
            }
        }
    }

    &:last-of-type {
        .image-wrapper {
            height: 100%;
            width: 10%;

            img {
                height: calc(100% + 32px * 2) !important;
                width: auto !important;
                // width: 250px !important;
                bottom: -32px !important;
                right: -32px !important;
                top: -32px !important;
                left: unset !important;
            }
        }
    }
}

.container {
    background: #f6f7f8 !important;
    height: 328px;
    position: relative;
    overflow: hidden;

    div.content {
        padding: 0;
    }
}

.content {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr;
    row-gap: 16px;
}

.text-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.title {
    @include feature-heading;

    max-width: 15ch;
}

.description {
    @include feature-description;
}

.image-wrapper {
    position: relative;
}


@include medium-breakpoint {
    .block-wrapper {
        &:first-of-type {
            .image-wrapper {
                img {
                    height: 142px !important;
                }
            }
        }

        &:nth-of-type(2) {
            .image-wrapper {
                img {
                    height: 200px !important;
                    width: 200px !important;
                }
            }
        }

        &:last-of-type {
            .image-wrapper {
                img {
                    height: calc(100%) !important;
                    bottom: 0 !important;
                    top: 0 !important;
                }
            }
        }
    }

    .container {
        height: 290px;
    }
}

@include media-tablet {
    .block-wrapper {
        &:first-of-type {
            .image-wrapper {
                img {
                    height: 182px !important;
                    bottom: -20px !important;
                }
            }
        }

        &:nth-of-type(2) {
            .image-wrapper {
                img {
                    height: 250px !important;
                    width: 250px !important;
                    bottom: -20px !important;
                    right: -20px !important;
                }
            }
        }

        &:last-of-type {
            .image-wrapper {
                img {
                    height: calc(100% + 20px * 2) !important;
                    bottom: -20px !important;
                    top: -20px !important;
                }
            }
        }
    }
}

@include media-tablet-portrait {
    .block-wrapper {
        &:last-of-type {
            .image-wrapper {
                img {
                    height: calc(100%) !important;
                    bottom: 0 !important;
                    top: 0 !important;
                    right: -100px !important;
                }
            }
        }
    }
    .container {
        height: 270px;
    }
}

@include media-mobile {
    .content {
        grid-template-columns: max-content minmax(calc(100% - 186px), 1fr);
    }

    .block-wrapper {
        &:first-of-type {
            .image-wrapper {
                width: 100%;

                img {
                    height: 139px !important;
                    bottom: 0px !important;
                }
            }
        }

        &:nth-of-type(2) {
            .image-wrapper {
                width: 100%;

                img {
                    height: 180px !important;
                    width: 180px !important;
                }
            }
        }

        &:last-of-type {
            .image-wrapper {
                width: 100%;
                img {
                    aspect-ratio: 1.847;
                    height: 120% !important; 
                    left: -10% !important;
                    top: 50% !important;
                    bottom: -20px !important;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .container {
        height: 220px;
    }

    .description {
        max-width: 12ch;
    }

    .image {
        width: 30%;
    }
}
