@import "~@ui/styles/tools";
@import "../../../../styles/variables.scss";

.container {
    display: grid;
    gap: 10px;
    --columns-count: 3;
    grid-template-columns: repeat(var(--columns-count, 3), 1fr);
}

.block {
    width: 100%;
    padding: 28px;
    background: $color-off-white;
}

.block__wide {
    grid-column: span var(--columns-count, 3);
}

.block__title {
    color: $color-title-lighter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin-bottom: 16px;
}

@include media-tablet {
    .container {
        --columns-count: 2;
    }

    .block {
        padding: 20px 0;
    }

    .block__title {
        font-size: 20px;
    }
}

@include media-mobile {
    .container {
        --columns-count: 1;
    }

    .block {
        padding: 20px;
    }
}