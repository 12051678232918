@import '~@ui/styles/variables';
@import '~@ui/styles/tools';

.show-more-link {
    width: fit-content;
    height: 100%;
    padding: 12px 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    color: #ffffff;
    background-color: #343637;
    border-radius: 30px;
    border: 1px solid #2b2d2e;

    transition: 0.2s background-color ease-in;

    &:visited {
        color: #ffffff;
    }

    &:not(:disabled):hover {
        color: #ffffff;
        text-decoration: none;
        background-color: #2b2d2e;
    }

    &:visited:not(:disabled):hover {
        color: #ffffff;
        text-decoration: none;
    }

    &:active:not(:disabled) {
        color: #ffffff;
        background-color: #2b2d2e;
        text-decoration: none;
    }

    &:visited:not(:disabled):active {
        color: #ffffff;
        text-decoration: none;
    }
}

.link-text {
    font-size: 14px;
}

@include media-tablet {
    .show-more-link {
        padding: 8px 12px;
    }

    .link-text {
        font-size: 12px;
    }
}


@include media-mobile {
    .show-more-link {
        padding: 12px 16px;
    }

    .link-text {
        font-size: 14px;
    }
}