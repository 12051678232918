@import '~@ui/styles/tools';

@import 'src/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.container {
    display: grid;

    padding: 4% 8%;

    background-color: #ffffff;

    &__large {
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        .title {
            grid-column: 1 / -1;
            max-width: 30ch;
        }

        @include media-mobile {
            grid-template-columns: 1fr;
        }
    }

    &__medium {
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      background-color: transparent;

      .title {
          grid-column: 1 / -1;
          max-width: 30ch;
      }

      @include media-tablet-portrait {
          grid-template-columns: 1fr;
      }
  }

    &__small {
        grid-template-areas:
            'title costs capital-reservations'
            'title offices offices';
        gap: 32px;
        grid-template-columns: repeat(3, 1fr);

        .title {
            max-width: 10ch;
        }

        @include small-breakpoint {
            gap: 20px;
        }

        @include media-tablet {
            grid-template-areas:
                'title title'
                'costs capital-reservations'
                'offices offices';
            grid-template-columns: 1fr 1fr;
        }

        @include media-tablet-portrait {
            grid-template-areas:
                'title'
                'costs'
                'capital-reservations'
                'offices';
            grid-template-columns: 1fr;
        }
    }
}

.title {
    grid-area: 'title';
    font-size: 48px;
    font-weight: 600;

    margin-bottom: 12px;

    strong {
        color: $color-primary;
        font-weight: 600;
    }
}

@include medium-breakpoint {
    .title {
        font-size: 40px;
    }
}

@include small-breakpoint {
    .title {
        font-size: 32px;
    }
}

@include media-tablet {
    .container {
        padding: 4% 16px;
    }

    .title {
        font-size: 24px;
    }
}

@include media-mobile {
    .title {
        font-size: 26px;
    }
}
