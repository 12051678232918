@import '~@ui/styles/tools';
@import '~@ui/styles/variables';
@import '@app/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

@mixin feature-heading {
    color: #1c2723;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;

    @include media-tablet {
        font-size: 24px;
    }
}

@mixin feature-description {
    font-size: 20px;
    line-height: normal;
    color: $color-text-light;
    margin-bottom: 0;

    @include media-tablet {
        font-size: 16px;
    }
}

.container {
    background: #f6f7f8 !important;
    overflow: hidden;

    div.content {
        padding: 0;
    }
}

.content {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr;
    justify-content: space-between;
    column-gap: 16px;

    @include media-mobile {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr max-content;
        row-gap: 16px;
        align-items: flex-end;
    }
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    row-gap: 16px;
}

.main-text {
    & h2, & h3 {
        @include feature-heading();
        max-width: 25ch;
    }

    & h2 a, & h3 {
        @include feature-heading();
    }

    & p {
        @include feature-description();
        max-width: 60ch;
        margin: 16px 0 0;
    }

    @include medium-breakpoint {
        & p {
            margin: 12px 0 0;
        }
    }

    @include small-breakpoint {
        & p {
            margin: 10px 0 0;
        }
    }

    @include xsmall-breakpoint {
        & p {
            margin: 10px 0 0;
        }
    }

    @include media-mobile {
        width: 100%;
    }
}

.footer-text {
    margin-top: auto;
    font-size: 20px;

    @include medium-breakpoint {
        font-size: 18px;
    }

    @include small-breakpoint {
        font-size: 16px;
    }

    @include xsmall-breakpoint {
        font-size: 14px;
    }

    @include media-mobile {
        margin-top: 15px;
    }
}

.image {
    margin-top: auto;
    height: 432px;
    aspect-ratio: 1.315;
    overflow: hidden;
    margin-bottom: -32px;

    position: relative;

    & > img {
        width: auto;
        height: 432px;
        object-fit: cover;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        position: relative;
    }

    @include medium-breakpoint {
        margin-right: -20px;
    }

    @include media-desktop-md {
        height: 400px;
    }

    @include small-breakpoint {
        height: 350px;
    }

    @include media-tablet-portrait {
        height: 250px;
    }

    @include media-mobile {
        margin-left: auto;
    }
}

.badge {
    position: absolute;
    top: 0;
    right: 30px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 4px 4px 20px 0 #AAAAAA40;
    gap: 16px;

    @include small-breakpoint {
        padding: 16px;
        border-radius: 14px;
    }

    @include media-tablet-portrait {
        top: unset;
        bottom: -10px;
        right: 20px;
        padding: 12px;
        gap: 12px;
        border-radius: 12px;
    }

    @include media-mobile {
        right: unset;
        left: 30px;
        bottom: 0;
    }
}

.badge__icon {
    width: 38px;
    height: auto;

    @include small-breakpoint {
        width: 30px;
    }

    @include media-tablet-portrait {
        width: 24px;
    }
}

.badge__value {
    color: #1E2021;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.2;

    @include small-breakpoint {
        font-size: 24px;
    }

    @include media-tablet-portrait {
        font-size: 20px;
    }
}
