@import '~@ui/styles/tools';
@import 'src/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin feature-heading {
    color: #1c2723;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;

    @include media-tablet {
        font-size: 20px;
    }
}

@mixin feature-description {
    font-size: 20px;
    line-height: normal;
    color: $color-text-light;
    margin-bottom: 0;

    @include media-tablet {
        font-size: 16px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: #ffffff;

    div.content {
        padding: 0;
    }
}

.content {
    height: 100%;
    display: grid;
    grid-template-rows: max-content max-content max-content;
}

.title {
    color: #1c2723;
    font-size: 96px;
    line-height: 114px;
    font-weight: 400;

    margin-bottom: 16px;
}

.description {
    @include feature-description;
    max-width: 30ch;

    margin-bottom: 96px;
}

.value {
    width: fit-content;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 20px;
    line-height: 24px;
    color: $color-primary;

    cursor: pointer;

}

@include small-breakpoint {
    .title {
        font-size: 44px;
        line-height: 52px;

        margin-bottom: 16px;
    }

    .description {
        margin-bottom: 28px;

        max-width: 30ch;
    }
}

@include media-mobile {
    .value {
        font-size: 14px;
    }
}