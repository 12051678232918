@import "@frontend/jetlend-web-ui/src/styles/tools";

.container {
    padding: 0;
    position: relative;
    width: 20vw;
    min-width: 390px;
    height: 295px;
    overflow: hidden;
    background: rgba(30, 32, 33, 1);
    border: none;
    outline: none;
    text-align: start;
};

.navigation_container {
    height: 100px;
    z-index: 4;
};

.navigation {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 28px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    z-index: 3  ;
};

.navigationItem {
    flex: 1;
};

.navigationItem_background {
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
};

.navigationItem_progress {
    height: 4px;
    margin-top: -4px;
    background: $color-off-white;
    border-radius: 4px;
};

.navigationItemActive {
    opacity: 1
};

.footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: 4;
};

.topCornerLogo {
    position: absolute;
    right: 0;
    top: 0;
};

.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
};

.headerLogo {
    height: 18px;
    width: 80px;
};

.backgroundContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.background {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headerTitle {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
};

.contentHeight {
    padding-top: 16px;
    height: 82%;
}

.contentContainer {
    z-index: 1; 
    min-height: 100%; 
    justify-content: flex-end;
    padding-top: 360px;
    width: 100%;
}

.scrollContent {
    padding: 0 20px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contentImage {
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 188px;
    height: 213px;
    max-width: 100%;
    max-height: 70%;
    text-align: center;
    margin-bottom: 12px;

    & img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }
}

.content__description {
    display: flex;
    flex-direction: column;
    height: 100%;

    margin-top: 60px;
}

.content__description--vertical-alignment-start {
    justify-content: start;
}

.content__description--vertical-alignment-center {
    justify-content: center;
}

.content__description--vertical-alignment-end {
    justify-content: end;
}

.content__footer {
    margin-top: 12px;
}

.bottomOverflow {
    position: absolute;
    bottom: 0;
    height: 148px;
    z-index: 2;
    width: 100%;
    background: linear-gradient(180deg, rgba(20, 22, 22, 0) 0%, #141616 78.02%);
    pointer-events: none;
};

.loaderIcon {
    height: 200px;
    display: flex;
    align-items: flex-end;
}

.iconContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        width: 100%;
    }
}

.headerTitle_text { 
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    color: #ffffff;
    margin-bottom: 16px;
};

.headerDescription_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(211, 211, 211, 1);
    letter-spacing: 0.3px;

    margin-bottom: 6px;
};

.headerValue_text {
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    color: #ffffff;
    letter-spacing: 0.3px;
};

.closeButtonWrapper {
    position: absolute;
    top: 16px;
    z-index: 5;
    padding: 16px 24px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.closeButton {
    position: relative;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    background: linear-gradient(139.44deg, #FFFFFF -29.13%, rgba(255, 255, 255, 0.1) 123.16%);
    backdrop-filter: blur(5.73932px);
    border-radius: 50%;
    transition: all 0.2s ease-in-out; 
    
    &::before {
        position: absolute;
        z-index: -1;
        content: '';
        width: 40px;
        height: 40px;
        background: linear-gradient(139.44deg, #FFFFFF -29.13%, rgba(255, 255, 255, 0.1) 123.16%);
        opacity: 0;
        border-radius: 50%;
    }

    &:hover {
        cursor: pointer;
        background: linear-gradient(139.44deg, #FFFFFF -29.13%, rgba(255, 255, 255, 0.3) 123.16%);

        &::before {
            position: absolute;
            z-index: -1;
            content: '';
            width: 40px;
            height: 40px;
            background: linear-gradient(139.44deg, #FFFFFF -29.13%, rgba(255, 255, 255, 0.1) 123.16%);
            opacity: 0.1;
            border-radius: 50%;
            box-shadow: 0px 15px 64px -12px rgba(17, 17, 17, 0.1);
        }
    }

    &:focus {
        background: linear-gradient(139.44deg, #FFFFFF -29.13%, rgba(255, 255, 255, 0.4) 123.16%);

        &::before {
            position: absolute;
            z-index: -1;
            content: '';
            width: 40px;
            height: 40px;
            background: linear-gradient(139.44deg, #FFFFFF -29.13%, rgba(255, 255, 255, 0.1) 123.16%);
            opacity: 0.3;
            border-radius: 50%;
        }
      
    }

    
};

.headerItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin-top: 20px;
    margin-bottom: 100px;
};

.slide {
    visibility: hidden;
    height: 0;

    &--active {
        visibility: visible;
        height: auto;

        display: block;
    }
}

@include media-mobile {
    .container {
        width: 100%;
        min-width: 100%;
    };

    .iconContainer {
        img {
            width: 80%;
        }
    }

    .header {
        animation: fadeIn 0.3s ease-in;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}