.container {
    padding: 48px;
    position: relative;
}

.image {
    -webkit-user-drag: none;
    user-select: none;
    padding: 5%;
    object-fit: contain;
}
