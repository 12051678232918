@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.container {
    padding: 0;

    .content {
        padding: 0;
    }
}

.title {
    font-size: $font-size-title;
    color: #1c2723;
    font-weight: 600;

    margin-bottom: 48px;
}

.info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main-text {
    overflow: hidden;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    transition: .5s;
    height: 300px;
}

.label {
    order: 3;
    width: fit-content;
}

@include medium-breakpoint {
    .title {
        font-size: 40px;
    }
}

@include small-breakpoint {
    .title {
        font-size: 32px;
    }
}

@include media-tablet {
    .title {
        font-size: 24px;
        margin-bottom: 32px;
    }

    .main-text {
        font-size: 16px;
        line-height: 23px;
    }
}

@include media-mobile {
    .title {
        font-size: 26px;
    }

    .main-text {
        height: 400px;
    }
}
