@import '~@ui/styles/tools';

@import '../../../../styles/variables.scss';

.container {
    --columns-count: 6;

    padding-top: 16px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(var(--columns-count, 6), 1fr);
    grid-template-rows: repeat(2, max-content);
}

.block {
    width: 100%;
    padding: 28px;
    background: $color-off-white;
    grid-column: span calc(var(--columns-count, 6) / 3);
    border-radius: 24px;

    &:nth-child(1),
    &:nth-child(2) {
        grid-column: span calc(var(--columns-count, 6) / 2);
    }

    &.block--wide {
        grid-column: span calc(var(--columns-count, 6) / 2);
    }
}

.title {
    color: $color-title-lighter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin-bottom: 16px;
}

.description {
    color: #8c8c8c;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: calc(100% - 5ch);
    margin: 0;
}

@include media-tablet {
    .container {
        padding-top: 0;
        padding-bottom: 24px;
    }

    .block {
        padding: 20px 0;

        grid-column: span calc(var(--columns-count, 6));

        &:nth-child(1),
        &:nth-child(2) {
            grid-column: span calc(var(--columns-count, 6));
        }
    }

    .title {
        font-size: 20px;
    }

    .description {
        font-size: 16px;
    }
}

@include media-mobile {
    .container {
        --columns-count: 1;
    }

    .block {
        padding: 20px;
    }
}
