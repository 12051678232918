@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.button__wrapper {
    display: flex;
    justify-content: center;
    margin-top: 82px;

    @include medium-breakpoint {
        margin-top: 65px;
    }
        
    @include small-breakpoint {
        margin-top: 50px;
    }
        
    @include xsmall-breakpoint {
        margin-top: 40px;
    }
    
    @include media-mobile {
        margin-top: 20px;
        
        & span {
            width: 100%;
        }
    }
}