@import '~@ui/styles/variables';
@import '~@ui/styles/tools';

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

.mobile-mode {
    display: none;
}

.desktop-mode {
    display: grid;
    grid-template-columns: repeat(3, minmax(360px,1fr));
}

.statistic {
    position: absolute;
    bottom: 0;
    left: calc(8% - 30px);
}

.statistic-block {
    position: relative;
    width: 100%;
    height: 180px;
    max-width: 360px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    background-color: transparent;

    row-gap: 12px;

    &--active {
        height: 250px;
        background-color: #1e2021;
        animation: 0.5s transparentToBlack, 0.5s grow;

        .statistic-title {
            color: #ffffff;
            animation: 0.5s blackToWhite;
        }

        .statistic-description {
            color: #d3d3d3;
            animation: 0.5s blackToGray;
        }

        .statistic-value {
            color: #ffffff;
            animation: 0.5s blackToWhite;
        }

        .statistic-more {
            display: block;
            opacity: 1;
            animation: 0.5s fadeIn;
        }

        .statistic-background {
            visibility: visible;
        }
    }

    &:not(:hover) {
        margin-top: auto;
    }
}

.statistic-title {
    font-size: 28px;
    line-height: 33px;
    font-weight: 400;
}

.statistic-description {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    max-width: 70%;

    color: #424242;

    @include media-tablet-portrait {
        max-width: 100%;
    }
}

.statistic-value {
    font-size: 36px;
    line-height: 43px;
    font-weight: 400;
}

.statistic-more {
    margin: auto 0 0 ;
    padding: 0;
    display: block;
    opacity: 0;
    width: fit-content;
    outline: none;
    border-radius: 30px;

    box-shadow: none;

    &--always-visible {
        opacity: 1;
    }
}

.statistic-background {
    position: absolute;
    display: block;
    visibility: hidden;
    inset: 0;
    pointer-events: none;

    overflow: hidden;
}

.image-wrapper {
    position: relative;
    height: 100%;
    aspect-ratio: 0.804;
    margin-left: auto;

    transform: translateY(33%) translateX(5%);
}

.contentImage {
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 188px;
    height: 213px;
    max-width: 100%;
    max-height: 70%;
    text-align: center;
    margin-bottom: 12px;

    & img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }
}


@include small-breakpoint {
    .desktop-mode {
        grid-template-columns: repeat(3, minmax(300px,1fr));
    }
}

@include media-tablet {
    .statistic {
        left: 16px;
    }

    .desktop-mode {
        grid-template-columns: repeat(3, minmax(230px,1fr));
    }

    .statistic-block {
        padding: 16px;
        height: 120px;
        row-gap: 8px;

        &--active {
            height: 190px;

            animation: 0.5s transparentToBlack, 0.5s growSmall;
        }
    }

    .statistic-title {
        font-size: 20px;
        line-height: 25px;
    }

    .statistic-description {
        font-size: 12px;
        line-height: 15px;
    }

    .statistic-value {
        font-size: 28px;
        line-height: 35px;
    }

    .image-wrapper {
        height: 70%;
    
        transform: translateY(70%) translateX(5%);
    }
}

@include media-tablet-portrait {
    .statistic {
        right: 16px;
    }

    .statistic-block {
        height: 140px;

        &--active {
            height: 190px;

            animation: 0.5s transparentToBlack, 0.5s growSmall;
        }
    }

    .desktop-mode {
        grid-template-columns: repeat(3, 1fr);
    }

    .statistic-title {
        font-size: 18px;
    }

    .image-wrapper {
        display: none;
        // height: 50%;
    
        // transform: translateY(130%) translateX(12%);
    }
}

@include media-mobile {
    .statistic {
        grid-template-columns: 1fr;
        height: 295px;
        width: 100%;

        left: 0;
        bottom: 0px;
    }

    .desktop-mode {
        display: none;
    }

    .mobile-mode {
        display: block;
        width: 100%;
        height: 100%;
    }
}

@keyframes transparentToBlack {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: #1e2021;
    }
}

@keyframes blackToWhite {
    0% {
        color: #1e2021;
    }
    100% {
        color: #ffffff;
    }
}

@keyframes blackToGray {
    0% {
        color: #424242;
    }
    100% {
        color: #d3d3d3;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes grow {
    0% {
        height: 180px;
    }
    100% {
        height: 250px;
    }
}

@keyframes growSmall {
    0% {
        height: 120px;
    }
    100% {
        height: 190px;
    }
}
